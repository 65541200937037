import React from 'react'
import TestimonialsSEO from '../components/testimonials/testimonialsSEO'
import Header from "../components/testimonials/header"
import Testimonials from '../components/testimonials/index'

const TestimonialsPage = () => {
  return (
    <div className="subpage-container">
      <TestimonialsSEO />
      <Header />
      <Testimonials />
    </div>
  )
}

export default TestimonialsPage
