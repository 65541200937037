import React from "react"
import SubHeader from "../shared/subHeader/index"
import { graphql, useStaticQuery } from "gatsby"

const TestimonialsHeader = () => {
  const {
    testimonialsPage: { title },
  } = useStaticQuery(graphql`
    query {
      testimonialsPage: sanityTestimonialsPage {
        title
      }
    }
  `)

  return (
    <SubHeader title={title} />
  )
}

export default TestimonialsHeader
