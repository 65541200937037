import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { TestimonialStyle, Section } from "./indexStyle";
import { Maybe, Query } from "@graphql-types";

const Testimonials = () => {
  const [currentCategory, setCurrentCategory] = useState<Maybe<string> | undefined>();
  const {
    sanityTestimonialsPage,
    allSanityCategory: { nodes },
  }: Query = useStaticQuery(graphql`
    query TestimonialsQuery {
      sanityTestimonialsPage {
        testimonials {
          _key
          name
          position
          testimonial
          category {
            _id
            _type
            CategoryName
            colorList
          }
        }
      }
      allSanityCategory {
        nodes {
          _id
          _type
          CategoryName
          colorList
        }
      }
    }
  `);

  const handleCategoryClick = (category: Maybe<string> | undefined) => () => {
    setCurrentCategory(category);
  };

  return (
    <Section>
      <div className="filter-bar">
        <button
          className={currentCategory ? "category" : "category active"}
          onClick={handleCategoryClick(undefined)}
        >
          All
        </button>
        {nodes.map(category => {
          if (!category) return null;
          const id = category._id;
          const className = currentCategory === id ? "category active" : "category";
          const hasTestimonals = sanityTestimonialsPage?.testimonials?.some(
            testimonial => testimonial?.category?._id === id,
          );

          if (!hasTestimonals) return null;

          return (
            <button
              key={category._id}
              className={className}
              onClick={handleCategoryClick(category._id)}
            >
              {category.CategoryName}
            </button>
          );
        })}
      </div>
      <TestimonialStyle>
        {sanityTestimonialsPage?.testimonials?.map(testimonial => {
          if (!testimonial) return null;
          if (testimonial.category?._id !== currentCategory) return null;

          return (
            <div key={testimonial._key} className="testimonial">
              <p>{testimonial.testimonial}</p>
              <h4>{testimonial.name}</h4>
              {testimonial.position && <h4>{testimonial.position}</h4>}
            </div>
          );
        })}
      </TestimonialStyle>
    </Section>
  );
};

export default Testimonials;
