import { SMALL_LAPTOP_BREAKPOINT, colors } from "@util/constants";
import styled from "styled-components";

export const TestimonialStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  padding: 8rem 0;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    padding: 4rem 0;
    grid-template-columns: 1fr;
  }

  .testimonial {
    padding: 4rem;
    border: 1px solid rgba(85, 30, 43, 0.2);
    border-radius: 0.4rem;

    h4,
    p {
      color: var(--red);
    }
    p {
      margin-bottom: 4rem;
    }
    &:nth-child(even) {
      background-color: ${colors.maroon};

      h4,
      p {
        color: white;
      }
    }
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 150rem;
  margin: 0 auto;

  .filter-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 4rem;
    position: sticky;
    padding: 15px 0;
    top: 107px;
    row-gap: 10px;
    flex-flow: wrap;
    background-color: ${colors.cream};
    border-bottom: 1px solid ${colors.maroon};

    .category {
      font-size: 1.6rem;
      padding: 0 1.5rem;
      color: #333;
      position: relative;
      background-color: transparent;
      border: none;

      &:not(:last-child)::after {
        content: "|";
        color: ${colors.maroon};
        position: absolute;
        right: -2.9vw;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
          display: none;
        }
      }

      &:first-child {
        color: ${colors.maroon};
      }

      &:hover {
        cursor: pointer;
        color: ${colors.maroon};
        font-weight: 700;
      }
    }
    .active {
      color: ${colors.maroon};
      font-family: "bold";
    }
  }
`;
